import Vue from "vue";
import App from "./App.vue";
import "./assets/css/tailwind.css";
import "./assets/css/style.css";
import router from "./router";

// import "./modules/vuetify-date.js";
import VueHtml2Canvas from "vue-html2canvas";
// VUEFIRE
import { firestorePlugin } from "vuefire";
Vue.use(firestorePlugin);
const numbertoChunks = (number, chunk) => {
  const arr = new Array(Math.floor(number / chunk))
    .fill(chunk)
    .concat(number % chunk);
  return arr;
};

Vue.prototype.$toChunks = numbertoChunks;
Vue.config.productionTip = false;
Vue.use(require("vue-moment"));
Vue.use(VueHtml2Canvas);

//Create Vue APP
new Vue({
  router,
  render: h => h(App),
}).$mount("#app");
