<template>
  <div
    class="lg:mx-auto px-4 py-16  sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 grid grid-cols-12 gap-6 items-center"
  >
    <div
      class="max-w-2xl mx-auto sm:max-w-xl md:max-w-2xl col-span-12 md:col-span-5"
    >
      <div class="text-left">
        <div
          class="max-w-xl mb-5 md:mx-auto sm:text-center lg:text-left lg:max-w-2xl md:mb-12"
        >
          <h2
            class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto"
          >
            <span class="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="b039bae0-fdd5-4311-b198-8557b064fce0"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7"></circle>
                  </pattern>
                </defs>
                <rect
                  fill="url(#b039bae0-fdd5-4311-b198-8557b064fce0)"
                  width="52"
                  height="24"
                ></rect>
              </svg>
            </span>
            Memento Vivere
          </h2>
          <p class="text-base text-gray-700 md:text-lg">
            Life is short. That’s all there is to say. Get what you can from the
            present – thoughtfully, justly. Marcus Aurelius We want you to know
            that your life is so precious.
          </p>
        </div>
        <form
          @submit.prevent="getWeeks"
          class="flex flex-wrap justify-center lg:justify-start gap-3 items-center w-full mb-4 md:flex-row md:px-16 lg:px-0"
        >
          <div class="text-left w-full">
            <label class="text-xs text-gray-700" for="name">Name</label>
            <input
              placeholder="Full Name"
              required=""
              v-model="name"
              type="text"
              class="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
            />
          </div>
          <!-- <vuetify-date
            v-model="birthday"
            label="Birth day"
            v-bind:options="options"
          /> -->

          <div class="text-left w-full">
            <label class="text-xs text-gray-700" for="birthday"
              >Birth day</label
            >
            <input
              placeholder="Date de Naissance"
              id="birthday"
              required=""
              type="date"
              v-model="birthday"
              class="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
            />
          </div>
          <div class="text-left w-full">
            <label class="text-xs text-gray-700" for="years">Years</label>
            <input
              placeholder="Enter number of years"
              required=""
              max="120"
              min="2"
              v-model="years"
              type="number"
              class="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
            />
          </div>
          <div class="grid grid-cols-2 gap-4">
            <button
              type="submit"
              class="inline-flex  items-center justify-center w-full h-12 px-6 font-semibold tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
            >
              Get Memento Vivere
            </button>
            <button
              v-if="weeksBetweenBithdayAndDeath > 0"
              @click="weeksBetweenBithdayAndDeath = 0"
              class="inline-flex  items-center justify-center w-full h-12 px-6 font-semibold tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-red-400 hover:bg-red-700 focus:shadow-outline focus:outline-none"
            >
              Reset
            </button>
          </div>
        </form>
        <p
          class="max-w-md mx-auto lg:mx-0 mb-10 text-xs text-gray-600 sm:text-sm md:mb-16"
        >
          Now you know
        </p>
      </div>
    </div>
    <div class="col-span-12 lg:col-span-6 ">
      <Paper
        v-if="weeksBetweenBithdayAndDeath > 0"
        :weeksAlive="weeksAlive"
        :weeksBetweenBithdayAndDeath="weeksBetweenBithdayAndDeath"
        :name="name"
      />
    </div>
  </div>
</template>
<script>
import Paper from "./Paper.vue";
export default {
  components: {
    Paper,
  },
  data() {
    return {
      name: "",
      birthday: null,
      weeksBetweenBithdayAndDeath: 0,
      weeksAlive: 0,
      output: null,
      years: null,
      options: {
        locale: "pt-BR",
        format: "MM/DD/YYYY",
        clearable: true,
      },
    };
  },
  methods: {
    getWeeks() {
      var today = new Date();
      today = this.$moment(today, "YYYY-MM-DD");
      if (today.diff(this.birthday, "year") >= this.years) {
        return alert("Are you already dead?");
      }

      var death = this.$moment(this.birthday, "YYYY-MM-DD").add(
        this.years,
        "years"
      );
      this.weeksAlive = today.diff(this.birthday, "week");
      this.weeksBetweenBithdayAndDeath = death.diff(this.birthday, "week");
    },
  },
};
</script>
