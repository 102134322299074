<template>
  <div class="flex flex-col justify-center gap-4 ">
    <div id="paper" class="transform scale-90">
      <div ref="printMe" class="py-8 px-4 rounded-lg back " style="">
        <p
          class="text-4xl text-black font-semibold tracking-wider w-full mb-2 handWriting"
        >
          Memento Vivere
        </p>
        <p
          class="text-black font-bold tracking-wider w-full mb-5 handWriting capitalize"
        >
          {{ name }}
        </p>

        <div class="grid grid-cols-12 mb-2   gap-3  p-4">
          <div
            v-for="(chunk, chunkIndex) in weeksBetweenBithdayAndDeathToChunks"
            :key="chunkIndex"
            class="col-span-1 grid grid-cols-4 grid-rows-5 gap-1"
          >
            <div
              class="col-span-1  rounded-full"
              :chunk="chunk"
              :chunkIndex="chunkIndex"
              :i="i"
              :order="(chunk = 20 * chunkIndex + i)"
              :class="
                (chunk =
                  20 * chunkIndex + i <= weeksAlive ? 'bg-black' : 'bg-white')
              "
              :style="
                (chunk =
                  20 * chunkIndex + i <= weeksAlive
                    ? ''
                    : 'border:1px solid #000;')
              "
              style="width:4px;height:4px;"
              v-for="i in chunk"
              :key="i"
            ></div>
          </div>
        </div>
        <p
          class=" mx-auto text-center font-bold text-black handWriting w-1/2 text-sm leading-6"
        >
          {{
            "« En te levant le matin, rappelle-toi combien précieux est le privilège de vivre, et de respirer d'etre heureux .»"
          }}
        </p>
        <p class="text-center text-black font-bold handWriting text-sm mt-4">
          Mark Aurèle
        </p>
      </div>

      <!-- <img v-else :src="output" /> -->
    </div>
    <div class="flex flex-col gap-4" v-if="output == null">
      <input
        placeholder="Email"
        required=""
        v-model="email"
        type="email"
        class="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
      />
      <button
        @click="send"
        v-if="output == null"
        class="inline-flex  items-center justify-center w-full h-12 px-6 font-semibold tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
      >
        <!-- {{ output == null ? "Generate Image" : "Image Generated Download" }} -->
        Send
        <div
          v-if="loading"
          class="loader ease-linear rounded-full border-2 border-t-2 ml-5 border-gray-200 h-6 w-6"
        ></div>
      </button>
    </div>
    <h2 v-else class="text-green-600 text-2xl font-medium">Sent</h2>
    <!-- <a
      v-if="output !== null"
      :download="`${name}-Memento.png`"
      :href="output"
      class="inline-flex  items-center justify-center w-full h-12 px-6 font-semibold tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
    >
      Download
      <div
        v-if="loading"
        class="loader ease-linear rounded-full border-2 border-t-2 ml-5 border-gray-200 h-6 w-6"
      ></div>
    </a> -->
  </div>
</template>

<script>
import { db } from "../db";
import axios from "axios";
export default {
  props: {
    weeksBetweenBithdayAndDeath: {
      required: true,
      type: Number,
    },
    weeksAlive: {
      required: true,
      type: Number,
    },
    name: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      email: null,
      output: null,
      loading: false,
    };
  },
  computed: {
    weeksBetweenBithdayAndDeathToChunks() {
      return this.$toChunks(this.weeksBetweenBithdayAndDeath, 20);
    },
    weeksAliveToChunks() {
      return this.$toChunks(this.weeksAlive, 20);
    },
  },
  methods: {
    add() {
      if (this.email.length > 0) {
        db.collection("emails").add({ email: this.email });
      }
    },

    async send() {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      var formData = new FormData();
      await this.print();

      await formData.append("email", this.email);
      await formData.append(
        "image",
        this.dataURLtoFile(this.output, "momento.png")
      );
      await axios
        .post("https://apimomento.hicaso.com/api/email", formData, config)
        .then(res => {
          console.log(res.data);
        });
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },

    async print() {
      const el = this.$refs.printMe;
      this.loading = true;
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        scale: 5,
        type: "dataURL",
        allowTaint: true,
      };
      this.output = await this.$html2canvas(el, options);
      this.loading = false;
    },
  },
  // downloadImg() {
  //   let url = this.remoteURL();
  //   fetch(url)
  //     .then(response => response.blob())
  //     .then(blob => {
  //       saveAs(blob, "image_name.jpg");
  //     });
  //   console.log("downloading", url);
  // },
};
</script>

<style scoped>
.paperText {
  font-family: "Times New Roman", Times, serif;
}
.handWriting {
  font-family: "Charmonman", cursive;
}
.back {
  /* background-image: url("../assets/bg-test.jpg");
  background-size: cover; */
  @apply bg-gray-100;
}
#paper {
  max-height: 80vh;
  overflow: auto;
}
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
