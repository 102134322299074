<template>
  <div class="home  min-h-screen flex items-center overflow-hidden">
    <Form />
  </div>
</template>

<script>
// @ is an alias to /src
import Form from "@/components/Form.vue";
import { db } from "../db";
export default {
  name: "Home",
  components: {
    Form,
  },
  data() {
    return {
      emails: [],
    };
  },
  firestore: {
    emails: db.collection("emails"),
  },
  // mounted() {
  //   console.log(db);
  // },
};
</script>
