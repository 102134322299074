//firebase
import firebase from "firebase";
import "firebase/firestore";

var dbConfig = {
  apiKey: "AIzaSyANfxrSQBcbOm81iI-3TcuGO_o5cBA5rRs",
  authDomain: "momento-vivere.firebaseapp.com",
  projectId: "momento-vivere",
  storageBucket: "momento-vivere.appspot.com",
  messagingSenderId: "592553457188",
  appId: "1:592553457188:web:bc6be11b94c7897dba8ab1",
  measurementId: "G-4Q8Q7R1F5Z",
  databaseURL: "/",
};
export const db = firebase.initializeApp(dbConfig).firestore();
db.settings({ timestampsInSnapshots: true });
